const Mission = () => {
    return (
        <div data-aos="fade" className="w-full p-8 md:p-20">
            <div
                data-aos="fade"
                data-aos-delay={300}
                className="border-box text-white bg-[#1e9ad6] rounded-3xl p-8 md:p-12"
            >
                <div
                    data-aos="fade-left"
                    data-aos-delay={500}
                    className="font-bold text-3xl mb-4"
                >
                    Our Mission
                </div>
                <p
                    data-aos="fade-left"
                    data-aos-delay={700}
                    className="text-sm md:text-xl"
                >
                    Our mission at Hermann Gmeiner School is to bring our vision to life by delivering a well-rounded, future-focused education. We are dedicated to fostering academic excellence through a modern curriculum that integrates technology, creativity, and experiential learning. By providing opportunities in sports and co-curricular activities, we aim to develop students' physical, emotional, and social well-being. We commit to cultivating an inclusive and nurturing environment where educators and students collaborate to enhance learning experiences. Through personalized guidance, diverse learning approaches, and character-building initiatives, we empower our students to grow into responsible and compassionate citizens.
                </p>
            </div>
        </div>
    );
};

export default Mission;
